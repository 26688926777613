import React from 'react';
import { Dialog, BookOrderTable, BookCreateDialog, Layout, Button, ViewTable } from '@/components';
import { LibAuthCode } from 'lib/Auth';
import LibEnum from 'lib/enum';
import WebApi from '@/web/api';
import { hideLoading, showLoading } from '@/components/message/message';

export default class PurchaseReturnOrderDetailPage extends React.PureComponent<RouterPropsType> {

  private bookCreateDialog = React.createRef<BookCreateDialog>();
  
  state = {
    loading: true,
    detailList: [],
    createdAt: '',
    createdByName: '',
    supplierName: '',
    orderStatus: '',
    orderStatusDesc: '',
    receivedStatus: '',
    receivedStatusDesc: '',
    remark: '',
    totalAmount: '',
    productKind: '',
    productCount: '',
    outStockRecordList: [],
    paymentRecordList: [],
  }

  componentDidMount() {
    this.findOrderDetail();
  }

  findOrderDetail = async () => {
    WebApi.purchase_return_order_details({
      purchaseReturnOrderId: this.props.match?.params.id
    })
    .then((data: any) => {
      this.setState({
        loading: false,
        orderStatus: data.orderStatus,
        orderStatusDesc: data.orderStatusDesc,
        receivedStatus: data.receivedStatus,
        receivedStatusDesc: data.receivedStatusDesc,
        supplierName: data.supplierName,
        remark: data.remark,
        detailList: data.detailList,
        totalAmount: data.totalAmount,
        productKind: data.productKind,
        productCount: data.productCount,
        createdAt: data.createdAt,
        createdByName: data.createdByName,
        outStockRecordList: data.outStockRecordList,
        paymentRecordList: data.paymentRecordList,
      });
    })
    .catch(e => {
      this.setState({
        deleted: true,
      });
    })
  }

  render() {
    return <Layout.Page loading={this.state.loading}>
      <Layout.Toolbar title="进货退货单详情">
        {
          this.state.orderStatus === LibEnum.PURCHASE_RETURN_ORDER_STATUS.WAIT_AUDIT.value &&
          <Button authCode={LibAuthCode.purchaseReturnOrder.update} type="primary" onClick={this.clickAudit}>审核通过</Button>
        }
        {
          this.state.orderStatus === LibEnum.PURCHASE_RETURN_ORDER_STATUS.WAIT_OUT_STOCK.value &&
          <Button authCode={LibAuthCode.purchaseReturnOrder.update} type="primary" onClick={this.clickOutStock}>商品出库</Button>
        }
        {
          this.state.receivedStatus === LibEnum.PAYMENT_STATUS_2.WAIT_PAYMENT.value &&
          <Button.Edit authCode={LibAuthCode.purchaseReturnOrder.update} onClick={() => {
            this.bookCreateDialog.current?.open();
          }}>添加收款记录</Button.Edit>
        }
        {
          this.state.orderStatus === LibEnum.PURCHASE_RETURN_ORDER_STATUS.WAIT_AUDIT.value &&
          <Button type="default" authCode={LibAuthCode.purchaseReturnOrder.update} icon={<i className="iconfont icon-zuofei"/>} onClick={this.clickCancel}>作废</Button>
        }
        {
          this.state.orderStatus === LibEnum.PURCHASE_RETURN_ORDER_STATUS.INVALID.value &&
          <Button type="primary" authCode={LibAuthCode.purchaseReturnOrder.delete} danger onClick={this.clickDelete}>删除</Button>
        }
      </Layout.Toolbar>
        <Layout flex1 scrollY>
         <Layout.Title>订单信息</Layout.Title>
          <div>
            <p style={{fontSize: 18, color: 'red'}}>
              <span style={{display: 'inline-block', width: 400}}>
              {this.state.orderStatusDesc}
              </span>
              {this.state.receivedStatusDesc}
            </p>
            <p>
              <span style={{display: 'inline-block', width: 400}}>
                <strong style={{marginRight: 10}}>退货单号：</strong>
                {this.props.match?.params.id}
              </span>
              <strong style={{marginRight: 10}}>下单时间：</strong>{this.state.createdAt}
            </p>
            <p>
              <span style={{display: 'inline-block', width: 412}}>
                <strong style={{marginRight: 10, marginLeft: 12}}>制单人：</strong>
                {this.state.createdByName}
              </span>
              <strong style={{marginRight: 10}}>供应商：</strong>
              {this.state.supplierName}
            </p>
            <p>
              <span style={{display: 'inline-block', width: 400}}>
                <strong style={{marginRight: 10}}>退货金额：</strong>
                {this.state.totalAmount}
              </span>
              <strong style={{marginRight: 10}}>备注说明：</strong>
              {this.state.remark}
            </p>
          </div>
          <Layout.Title icon="icon-form-shangpin" style={{marginTop: 30}}>商品明细</Layout.Title>
          <ViewTable
            flex1
            pagination={false}
            dataSource={this.state.detailList}
            style={{height: 'auto'}}
            scroll={{y: '100%', x: true}}
            columns={[
              {
                title: '商品',
                dataIndex: 'productName',
                width: 200,
              },
              {
                title: '规格',
                dataIndex: 'skuName',
                width: 300,
              },
              {
                title: '仓库',
                dataIndex: 'repositoryName',
                width: 150,
              },
              {
                title: '进货价格',
                align: 'right',
                dataIndex: 'purchasePrice',
                width: 100,
              },
              {
                title: '退货价格',
                align: 'right',
                dataIndex: 'returnPrice',
                width: 100,
              },
              {
                title: '退货数量',
                align: 'right',
                dataIndex: 'returnCount',
                width: 100,
              },
              {
                title: '金额小计',
                align: 'right',
                dataIndex: 'totalAmount',
                width: 100,
              },
              {
                title: '备注',
                dataIndex: 'remark',
                width: 'auto',
              },
          ]}/>

          <Layout.Title icon="icon-chuku" style={{marginTop: 30}}>出库记录</Layout.Title>
          <ViewTable
            flex1
            pagination={false}
            style={{height: 'auto'}}
            dataSource={this.state.outStockRecordList}
            scroll={{y: '100%'}}
            columns={[
              {
                title: '商品',
                dataIndex: 'productName',
                width: 200,
              },
              {
                title: '规格',
                dataIndex: 'skuName',
                width: 300,
              },
              {
                title: '仓库',
                dataIndex: 'repositoryName',
                width: 150,
              },
              {
                title: '进货价格',
                align: 'right',
                dataIndex: 'purchasePrice',
                width: 100,
              },
              {
                title: '出库数量',
                align: 'right',
                dataIndex: 'returnCount',
                width: 100,
              },
              {
                title: '出库时间',
                align: 'center',
                dataIndex: 'createdAt',
                width: 'auto',
              },
            ]}/>

          <Layout.Title icon="icon-shoukuan" style={{marginTop: 30}}>收款记录</Layout.Title>
          <div style={{paddingBottom: 60}}>
            <BookOrderTable
              mode="received"
              dataSource={this.state.paymentRecordList}
            />
            <BookCreateDialog
              ref={this.bookCreateDialog}
              shouldAmount={this.state.totalAmount}
              actualAmount={this.state.totalAmount}
              onOk={this.clickCreateReceived}
            />
          </div>
        </Layout>
    </Layout.Page>
  }

  // 作废
  private clickCancel = () => {
    Dialog.confirm({
      title: '作废确认',
      content: '确认作废吗?',
      onOk: async () => {
        WebApi.purchase_return_order_cancel({
          purchaseReturnOrderId: this.props.match?.params.id
        })
        .then(() => {
          showSuccess('订单已作废');
          this.findOrderDetail();
        })
      }
    });
  }

  // 删除
  private clickDelete = () => {
    Dialog.confirm({
      content: '确认删除吗?',
      onOk: async () => {
        WebApi.purchase_return_order_delete({
          purchaseReturnOrderId: this.props.match?.params.id
        })
        .then(() => {
          showSuccess.delete();
        })
      }
    });
  }

  // 审核
  private clickAudit = () => {
    Dialog.confirm({
      content: '确认通过审核吗?',
      onOk: () => {
        WebApi.purchase_return_order_audit({
          purchaseReturnOrderId: this.props.match?.params.id,
        })
        .then(() => {
          showSuccess.save();
          this.findOrderDetail();
        });
      }
    });
  }

  // 出库
  private clickOutStock = () => {
    Dialog.confirm({
      content: '确认出库吗?',
      onOk: () => {
        WebApi.purchase_return_order_out_stock({
          purchaseReturnOrderId: this.props.match?.params.id,
        })
        .then(() => {
          showSuccess.save();
          this.findOrderDetail();
        });
      }
    });
  }

  // 添加收款记录
  private clickCreateReceived = ({actualAmount, datetime, remark}) => {
    showLoading(1);
    WebApi.purchase_return_order_payment_record_create({
      purchaseReturnOrderId: this.props.match?.params.id,
      actualAmount,
      datetime,
      remark,
    })
    .then(() => {
      this.bookCreateDialog.current?.close();
      showSuccess.save();
      this.findOrderDetail();
    })
    .finally(() => {
      hideLoading();
    })
  }
}